<template>
  <b-modal
    id="modal-choose-wa"
    ref="modal-choose-wa"
    hide-footer
    hide-header
    centered
    :modal-class="{padding:'0'}"
    size="lg"
    @hidden="$emit('resetWA')"
  >
    <div class="lg:py-[10px] grid justify-center">
      <div class="flex items-center gap-1 justify-center">
        <span
          class="text-[20px] text-center font-bold text-black"
        >Integrasi WhatsApp dengan Komchat </span><b-img
          width="40"
          class=""
          src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-komchat.svg"
          alt=""
        />
      </div>
      <div class="text-center text-[16px]">
        Pilih Sesuai dengan Kebutuhan CRM-mu
      </div>
      <div class="grid gap-5 mt-3 w-full justify-center lg:grid-cols-2">
        <div class="rounded-lg bg-[#F8F8F8] p-[32px]">
          <div class="flex gap-3">
            <img
              src="https://storage.googleapis.com/komerce/assets/LP-Komchat/chat-bg.svg"
              alt=""
            >
            <div>
              <div class="grid">
                <div>
                  Komchat
                </div>
                <div class=" font-semibold text-[20px]">
                  Starter
                </div>
              </div>
            </div>
          </div>
          <div class="flex  gap-2 mt-2">
            <div>
              <b-icon
                class="text-xs text-[#08A0F7]"
                icon="info-circle"
              />
            </div>
            <div class="text-sm">
              <span class="flex items-center gap-1">
                Gratis untuk user kategori <span><img
                  width="13"
                  src="https://storage.googleapis.com/komerce/assets/svg/Saudagar.svg"
                  alt=""
                ></span> Saudagar
              </span>
              <span class="flex items-center gap-1">
                dan <span><img
                  width="13"
                  src="https://storage.googleapis.com/komerce/assets/icons/badge_juragan.svg"
                  alt=""
                ></span>
                Juragan. Pelajari Benefitnya <a
                  href="https://bantuan.komerce.id/id/article/status-membership-komship-dan-manfaatnya-oavgab/?bust=1686796830496"
                  target="_blank"
                  style="color: #08A0F7; text-decoration: underline;"
                >disini</a>
              </span>
            </div>
          </div>
          <div class="mt-1">
            Hanya
          </div>
          <div>
            <div>
              <span class="font-bold text-[24px]">Rp.</span><span class="font-bold text-[42px]">{{ findPrice(1) }},-</span><span>/Bulan</span>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in pointModalPricing[0]"
              :key="index"
            >
              <div>{{ item.title }}</div>
              <div
                class="flex mb-1 leading-5 items-center gap-2"
              >
                <b-icon
                  v-if="item.active === true"
                  class="text-[#34A770] text-[26px]"
                  icon="check-circle-fill"
                />
                <b-icon
                  v-if="item.active === false"
                  class="text-[26px] text-primary"
                  icon="x-circle-fill"
                />
                <div>
                  {{ item.point }}
                </div>
              </div>
            </div>
          </div>
          <b-button
            v-if="!isKomchatOnly && !isUpgrade"
            class="mt-1"
            pill
            variant="primary"
            block
            @click="submit(1)"
          >
            Aktifkan
          </b-button>
        </div>
        <div
          class="rounded-lg p-[32px] bg-gradient-to-b from-[#FF6A3A] to-[#CF6A0D]"
        >
          <div class="flex gap-3">
            <img
              src="https://storage.googleapis.com/komerce/assets/LP-Komchat/chat-notif-bg.svg"
              alt=""
            >
            <div>
              <div class="grid">
                <div class="text-white">
                  Komchat
                </div>
                <div class="text-white font-semibold text-[20px]">
                  Basic
                </div>
              </div>
            </div>
          </div>
          <div class="text-white text-sm mt-2">
            Dibantu pemulihan akun dari banned Meta
          </div>
          <div class="text-white mt-2">
            Hanya
          </div>
          <div class="mt-[5px]">
            <div>
              <span class="font-bold text-white text-[24px]">Rp.</span><span class="font-bold text-white text-[42px]">{{ findPrice(5) }},-</span><span class="text-white">/Bulan</span>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in pointModalPricing[1]"
              :key="index"
              class="text-white"
            >
              <div>{{ item.title }}</div>
              <div
                class="flex mb-1 items-center leading-5 gap-2"
              >
                <b-icon
                  v-if="item.active === true"
                  class="text-white text-[26px]"
                  icon="check-circle-fill"
                />
                <div>
                  {{ item.point }}
                </div>
              </div>
            </div>
          </div>
          <b-button
            pill
            variant="outline-light"
            block
            class="mt-1"
            @click="submit(5)"
          >
            <b-spinner
              v-if="loadingSubcription"
              small
            />
            Aktifkan
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { pointModalPricing } from '../static'
import { apiSubs } from '../service/api.whatsapp.services'

export default {
  props: {
    dataUser: {
      type: Array,
      default: () => [],
    },
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    reActivate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pointModalPricing,
      isKomchatOnly: this.$store.state.dashboard.profile.is_komship === 0 && this.$store.state.dashboard.profile.is_komchat === 1,
      badge: this.$store.state.whatsapp.check_wa.is_user_type,
      loadingSubcription: false,
      listPlan: [],
    }
  },
  async mounted() {
    await this.$store.dispatch('komchat/getSubsPlanList')
    this.listPlan = this.$store.state.komchat.subsPlanList
  },
  methods: {
    findPrice(id) {
      const price = this.listPlan.find(item => item.id === id)
      return price.nominal.toLocaleString('id-ID')
    },
    submit(id) {
      if (this.isUpgrade === true || this.reActivate === true) {
        this.checkSaldo(id)
      } else {
        this.$emit('submit', id)
      }
    },
    checkSaldo(id) {
      const selectedPlan = this.listPlan.find(item => item.id === id)
      if (!selectedPlan) {
        console.error('Plan not found')
        return
      }
      const subscriptionFee = selectedPlan.nominal
      const kompayBalance = this.$store.state.saldo.saldo
      this.$bvModal.hide('modal-choose-wa')

      if (this.badge === 'Regular' || (this.badge !== 'Regular' && id >= 5)) {
        const confirm = kompayBalance < subscriptionFee ? 'Top Up Saldo' : 'Bayar Sekarang'

        this.$swal({
          title: 'Konfirmasi Berlangganan',
          html: `
        <span>
          Bayar <b>${this.$options.filters.rupiah(subscriptionFee)}</b> dengan Kompay untuk mengaktifkan <b>${id < 5 ? 'Komchat Starter' : 'Komchat Basic'}</b> selama <b>30 hari</b>.
          <span>Pastikan saldo Kompay kamu cukup untuk berlangganan Notif Pengiriman.</span>
        </span>
        <div class="mt-1">Saldo Kompay : <span class="font-bold text-primary">${this.$options.filters.rupiah(kompayBalance)}</span></div>
      `,
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: confirm,
          width: 600,
          confirmButtonClass: 'btn btn-primary',
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          cancelButtonText: 'Batal',
          cancelButtonColor: '#FFFFFF',
          cancelButtonClass: 'btn btn-outline-primary text-primary',
        }).then(response => {
          if (response.isConfirmed) {
            if (kompayBalance < subscriptionFee) {
              this.$router.push('/keuangan/saldo')
            } else {
              this.subscribe(id)
            }
          } else {
            this.$bvModal.show('modal-choose-wa')
          }
        })
      } else {
        this.$swal({
          title: 'Konfirmasi Berlangganan',
          html: `<span>sebagai <b>${this.badge}</b> kamu bisa menggunakan <b>Komchat Starter secara gratis</b> Aktifkan sekarang!</span>`,
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Aktifkan',
          width: 500,
          confirmButtonClass: 'btn btn-primary',
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          cancelButtonText: 'Batal',
          cancelButtonColor: '#FFFFFF',
          cancelButtonClass: 'btn btn-outline-primary text-primary',
        }).then(response => {
          if (response.isConfirmed) {
            this.subscribe(id)
          } else {
            this.$bvModal.show('modal-choose-wa')
          }
        })
      }
    },
    async subscribe(id) {
      try {
        this.loadingSubcription = true
        await apiSubs(id)
        this.$toast_success({ message: 'Berhasil melakukan pembayaran' })
        this.$bvModal.hide('modal-choose-wa')
        await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
        await this.$store.dispatch('saldo/getBalanceSummary')
        this.loadingSubcription = false
      } catch (error) {
        this.loadingSubcription = false
        this.$toast_error({ message: 'Gagal melakukan pembayaran' })
        this.$bvModal.hide('modal-choose-wa')
      }
    },
  },
}

</script>
